@import "../sass/variables.scss";
@import "../sass/mixins.scss";
#header-img{
    .back-wrapper{
        padding-right: $header-padding;
        padding-left: $header-padding;
        padding-top: 10vw;
        margin-bottom: 2rem;
        @include mq($md){
            padding-right: 15px;
            padding-left: 15px;
            padding-top: 5rem;   
        }
        .background-img{
            height:80vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 36px;
            padding:3.125rem;
            @include mq($xls){
                padding:1rem;
                border-radius:18px;
            }
            display: grid;
            align-content: flex-end;
            color:$white_color;
            .icon-img{
               width:3.125rem; 
               margin-right: 1.5rem;
               @include mq($xls){
                   margin-right: 1rem;
               }
            }
        }

    }
}