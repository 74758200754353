@import "../sass/variables.scss";
@import "../sass/mixins.scss";

#cycle {
    .card {
        padding-bottom: 20px;
        color:#3399cc;
    }

    // margin-bottom: 4.8%;
    

    @include mq($xxlg) {
        .card {
            min-width: 15.66666666666667%;
            margin-bottom: 0%;
            padding-left: 2.342606149341142%;
            padding-right: 2.342606149341142%;
            margin-left: 1%;
            margin-right: 0;

            &:nth-child(1) {
                margin-left: 0px;
            }

        }
    }

    @include mq($xlg) {
        .card {
            min-width: 32%;
            margin-bottom: 2%;
            padding-left: 2.342606149341142%;
            padding-right: 2.342606149341142%;
            margin-left: 2%;
            margin-right: 0;

            &:nth-child(1) {
                margin-left: 0px;
            }

            &:nth-child(4) {
                margin-left: 0px;
            }
        }
    }

    @include mq($lg) {
        .card {
            min-width: 48%;
            margin-bottom: 4%;
            padding-left: 2.342606149341142%;
            padding-right: 2.342606149341142%;
            margin-left: 4%;
            margin-right: 0;

            &:nth-child(1),
            &:nth-child(3),
            &:nth-child(5) {
                margin-left: 0px;
            }

            &:nth-child(4) {
                margin-left: 4%;
            }

            &:nth-child(6) {
                margin-bottom: 0%;
            }

            &:nth-child(5) {
                margin-bottom: 0%;
            }
        }
    }

    @include mq($xls) {
        .card {
            min-width: 48%;
            margin-bottom: 4%;
            padding-left: 2.342606149341142%;
            padding-right: 2.342606149341142%;
            margin-left: 4%;
            margin-right: 0;

            &:nth-child(1),
            &:nth-child(3),
            &:nth-child(5) {
                margin-left: 0px;
            }

            &:nth-child(4) {
                margin-left: 0%;
            }

            &:nth-child(5) {
                margin-bottom: 4%;
            }

            &:nth-child(6) {
                margin-bottom: 0%;
            }
        }
    }


    @include mq($xls) {
        .card {
            min-width: 100%;
            margin: 0;
            padding: 0;
            padding-bottom: 20px;
            margin-bottom: 7.8125%;
        }



    }

    @include mq($xxs) {
        .card {
            min-width: 100%;
            margin: 0;
            padding-bottom: 20px;
            margin-bottom: 7.8125%;
        }



    }

    .cycle-item {
        @include card;
        padding-left: 20px;
        padding-right: 20px;

        @include mq($xls) {
            padding-left: 10px;
            padding-right: 10px;
        }

        height: auto;
        // @include mq($md){
        //     -ms-flex: 0 0 30.333333%;
        //     flex: 0 0 30.333333%;
        //     max-width: 50.333333%

        // }

        .title-cycle {
            @include in_btn_space;
            color: $white_color;
            font-family: $bold_poppins;
            font-size: 25px;
            text-align: center;
            width: 100%;

            @include mq(600px) {
                font-size: 19px;
            }
        }

        .icon {
            width: 100%;
            display: grid;
            place-items: center;

            img {
                max-height: 147px;
                width: auto;
                margin-top: 40px;
            }
        }

        .cycle-details {
            padding-right: 12px;
            padding-left: 12px;
            @include in_btn_space;
            font-family: $poppins_regular;
            font-size: 13px;
            color: $gray_color;
            width: 100%;
            margin-bottom: 10px;
            margin-top: 60px !important;
        }

        .learn-more {
            width: 100%;
            display: grid;
            place-items: center;

            .btn-learn-more {
                @include hover;
                border-radius: 16px;
                border-style: solid;
                border-width: 10px;
                width: 50%;
                padding-top: 12px;
                padding-bottom: 12px;
                border-color: $page_color;
                background-color: $blue_color;
                font-family: $bold_poppins;
                font-size: 14px;
                color: $white_color;
                text-transform: capitalize;
                position: relative;
                top: 30px;

                @include mq(600px) {
                    top: 0px;
                    border-width: 0px;
                    border-radius: 4px;
                    margin-bottom: 20px;
                }

                @include mq($md) {
                    top: 0px;
                    border-width: 0px;
                    border-radius: 4px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}