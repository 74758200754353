@import "../sass/variables.scss";
@import "../sass/mixins.scss";

#team {
    padding-left: $header-padding;
    padding-right: $header-padding;
    width: 100%;
    height: 100%;

    @include mq($md) {
        padding-left: 15px;
        padding-right:15px;
    }
    @include mq($xls) {
        padding-left: 15px;
        padding-right:15px;
    }
}