@import "../sass/variables.scss";
@import "../sass/mixins.scss";

#history {
    // padding-top: ($header-padding);
    padding-bottom: ($header-padding);

    @include mq($xls) {
        padding-top:0px;
    }

    .history {
        padding-left: $header-padding;
        padding-right: $header-padding;

        @include mq($md) {
            padding-right: 30px;
            padding-left: 30px;
        }
        @include mq($xls) {
            padding-right: 0px;
            padding-left: 0px;
        }
    }

    .col-lg-5 {
        max-width: calc(50% - 25px);
        flex: 0 0 calc(50% - 25px);
        padding-left: 30px;

        @include mq($md) {
            max-width: 100%;
            flex: 0 0 100%;
            padding: 0px !important;
        }
    }
    .odd{
        padding-right:30px;
    }

    .back-img {
        height: calc(100% - 100px);
        background-image: url("https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887425/images/history/Rwanda_e5sz6d.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include mq($md){
            height:50vh;
            margin-left: 45px;
            margin-bottom: 30px;
        }
        @include card;
    }

    .line-divider {
        @include mq($md) {
            display: none;
        }

        .rounded-circle {
            height: 50px;
            width: 50px;
            border: 1px solid $white_color;
            display: grid;
            place-items: center;
            background-color: $page_color;

            .inner-circle {
                height: 25px;
                width: 25px;
                background-color: $white_color;
            }
        }

        .line {
            display: grid;
            place-items: center;
            height: 100%;

            &::after {
                content: "";
                width: 2px;
                height: 100%;
                background-color: $white_color;
            }
        }
    }


    .content {
        .col-md-6 {
            padding-left:30px;
            @include mq($xls){
                padding: 0 !important;
            }
        }

        .content-data {
            @include mq($md) {
                max-width: calc(100% - 50px);
                padding-left:15px;
            }
        }

        .line-divider-mobile {
            @include mq($xxlg) {
                display: none;
            }

            @include mq($md) {
                display: block;
            }

            .rounded-circle {
                height: 50px;
                width: 50px;
                border: 1px solid $white_color;
                display: grid;
                place-items: center;
                background-color: $page_color;
                @include mq($xls) {
                    height: 30px;
                    width: 30px;
                }

                .inner-circle {
                    height: 25px;
                    width: 25px;
                    background-color: $white_color;
                    @include mq($xls) {
                        height: 15px;
                        width: 15px;
                    }
                }
            }

            .line {
                display: grid;
                place-items: center;
                min-height: 64rem;
                position: absolute;
                z-index:-1000;
                display: grid;
                place-items: center;
                width: 50px;
                @include  mq($xls){
                    width:30px;
                }
                .inner-line {
                    width: 2px;
                    min-height: 100%;
                    background-color: $white_color;
                    position: absolute;
                    z-index:-1000;
                }
            }
        }

        .year {
            font-size: 40px;
            font-family: $poppins_thin;
            color: $white_color;
            line-height: 47px;
            @include mq($xls) {
                justify-content: flex-start !important;
            }
        }

        .action {
            font-size: 24px;
            font-family: $poppins_black;
            color: $white_color;
            line-height: 28px;

            @include mq($xls) {
                justify-content: flex-start !important;
                text-align: start !important;
            }
        }

        .date {
            font-size: 12px;
            font-family: $poppins_light;
            color: $white_color;
            line-height: 1;
            letter-spacing: 2px;
            margin-bottom: 32px;

            @include mq($xls) {
                justify-content: flex-start !important;
                text-align: start !important;
            }
        }

        .event-details {
            font-size: 16px;
            font-family: $poppins_regular;
            color: $white_color;
            line-height: 24px;
            letter-spacing: 0px;
            margin-bottom: 12px;
            @include mq($xls){
                text-align: start !important;
            }

        }

        .location {
            font-size: 12px;
            font-family: $poppins_regular;
            color: $white_color;
            line-height: 24px;
            letter-spacing: 0px;
            margin-bottom: 100px;

            @include mq($md) {
                margin-bottom: 20px;
            }

            .location-icon {
                margin-right: 15px;

                img {
                    height: 16px;
                }
            }

            .country {
                margin-left: 15px;
                // margin-right:15px;

                img {
                    height: 16px;
                }
            }
        }
    }



}