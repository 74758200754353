@import "../sass/variables.scss";
@import "../sass/mixins.scss";


#all-services{
    display: flex;
    flex-wrap: nowrap;
    width:auto;
    overflow-x: hidden;
    width:100%;
}
#service {
    padding-right: $header-padding;
    padding-left: $header-padding;
    margin-bottom: 2rem;
    min-width:100%;
    max-width:100%;
    @include mq($xxlg){
        min-height:0vw;
    }
    @include mq($md){
        padding-right: 15px;
        padding-left: 15px;
    }

    .row {
        background-color: $black_ligtened;
        border-radius: 18px;
        @include card;
        .col-md-8 {
            img {
                width: 100%;
                height: auto;
                border-radius: 18px;
            }
        }

        .col-md-4 {
            padding: 20px;
            h3{
                margin-bottom: 15px;
                color: $white_color;
                font-family: $bold_poppins;
                font-size: 20px;
            }
            h6{
                color: $white_color;
                font-family: $poppins_regular;
                font-family: $poppins_regular;
                font-size: 13px;
                color: $gray_color;
                line-height: 20px;
                text-align: left;
                opacity: .9;
            }
        }
    }

}