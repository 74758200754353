// 1080*1350
@import "../sass/variables.scss";
@import "../sass/mixins.scss";

.project-icon {
  max-width: 40px;
  float: right;
  height: 100px;
  // background-color:red;
  flex: auto;
  pointer-events: auto !important;
  // position: fixed;
}

.project-unique-detail {
  min-width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 5vw 10vw 5vw 10vw;
  background-color: rgba($color: #000000, $alpha: 1);
  outline: 0;
  border: 0px solid #000;
  display: block !important;

  @include mq($md) {
    padding-top: 60px !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
  }

  @include mq($xls) {
    padding-top: 60px !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
  }

  .kanamugire {
    @include mq($xls) {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 15px;
    }
  }

  .cancel-btn {
    img {
      width: 50px;
      height: auto;
      top: 5vw;
      right: 10vw;
      float: right;
      position: absolute;

      @include mq($xlg) {
        top: 32px;
        width: 30px;
      }

      @include mq($md) {
        top: 30px;
        width: 30px;
        right: 15px;
      }

      @include mq($xls) {
        top: 20px;
        width: 30px;
        right: 15px;
      }
    }
  }

  .wrapper {
    background-color: $black_ligtened;
    border-radius: 30px;
    margin-top: 5vw;

    .header-img {
      img {
        width: 100%;
        height: auto !important;
        border-radius: 30px;
      }
    }

    .project-body {
      padding: 2rem 2rem 2rem 2rem;

      @include mq($xls) {
        padding: 15px;
      }

      h1 {
        font-family: $bold_poppins;
        color: $white-color;
        @include mq($xls) {
          font-size: 2rem;
          // margin-bottom: 1rem;
        }
        font-size: 3rem;
      }

      h3 {
        font-family: $poppins_thin;
        color: $white-color;
        font-size: 3rem;
        padding-bottom: 4vh;
        @include mq($xls) {
          font-size: 1rem;
          // padding-bottom: 15px;
        }
      }

      h6 {
        font-family: $poppins_regular;
        color: $white-color;
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 5vh;
        opacity: 0.75;
      }

      .row {
        .card {
          background-color: rgba($color: #000000, $alpha: 0);
          border-color: rgba($color: #000000, $alpha:0);
          padding-bottom: 2rem;
          width: calc((100%/2) - 1rem);

          @include mq($xls) {
            padding-bottom: 2rem;
            width: 100%;
          }

          img {
            width: 100%;
            height: auto;
            border-radius: 18px;
            @include card;
            box-shadow: none;
          }
        }

        .col-md-12 {
          --video--width: 640;
          --video--height: 360;

          position: relative;
          padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
          /* 41.66666667% */
          overflow: hidden;
          max-width: 100%;
          background: black;
          @include card;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          border-radius:18px;
        }

        .left-arrow {
          font-size: 30px;
          color: $white-color;
          margin-top: 100px;
          margin-bottom: 100px;
        }
      }
    }
  }
}

.privacy {
  display: block;
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;
}