@import "../sass/variables.scss";
@import "../sass/mixins.scss";

#back-img {
    position: absolute;
    top: 0;
    right:0;
    z-index: -100;
    
    width:55vw;
    height: 100vh;
    @include mq($xls){
     width:100vw;
     top:16vh;
    }
}
#home-window{
    // background-image: url("../images/careers/Press\ play-bro.svg");
    // background-position: center;
    // position: absolute;
    // top: 0;
    // z-index: 2;
    background-color:line;
    background-size:100%;
    width: 100vw;
    height:100vh;
    margin-bottom:81px;

    .text-wrapper {
        height: 100vh;
        display: grid;
        place-items: center;
        width: 36vw;
        margin-left: $header-padding;
        @include mq($xxlg) {
            width: 40vw;
            h3{
                font-size:3.5rem !important;
                font-family: $bold_poppins;
                span{
                    color:$blue_color;
                }
            }
        }

        @include mq($xlg) {
            width: 36vw;
               h3 {
                   font-size: 1.75rem !important;
               }
        }

        @include mq($lg) {
            width: 36vw;
        }

        @include mq($md) {
            width: 35vw;
            margin-left: 0;
        }

        @include mq($xls) {
            width: 72vw;
            height:63vh;
            h3 {
                font-size: 1.6rem !important;
            }
        }

        .text {
            color: $white_color;
        }
    }
}
#careers{
    
    padding-left: $header-padding;
    padding-right: $header-padding;
    margin-bottom: 100px;
    @include mq($md){
        padding-left:15px;
        padding-right: 15px;
    }
    #logo{
        animation-name: none;
    }
    .circle-wrapper{
        position: absolute;
        z-index:-2;
        top:0;
        // left:0;
        width:100vw;
        height:100vh;
        display: flex;
        justify-content:flex-end;
        
        .circle-2-wrapper{
            width:50vw;
            height:100%;
            margin-top:15;
            display: fgrid;
            justify-content: center;
            background-image: url("https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887320/images/careers/3_bjwpz2.png");
            background-size: cover;
            .circle{
                position:absolute;
                 z-index: -3;
                width:500px;
                height:500px;
                background-color: $blue_color;
            }
        }
    }
    
    .card {
        margin-bottom: 2rem;
        background-color: rgba($color: #000000, $alpha: 0);
        width:calc((100%/4) - 1rem);
        @include mq($lg){
            width:calc(50% - 1rem);
        }
        @include mq($xls){
            width:100%;
        }
    }
    h1{
        font-family: $poppins_regular;
        color:$white_color;
        margin-bottom:3.5rem;
        font-family: $bold_poppins;
        text-underline-offset: 10;
        @include mq($xls){
            font-size:30px;
            margin-bottom: 1.5rem;
        }
        @include mq($xms){
            font-size:30px;
            margin-bottom: 1.5rem;
        }
        @include mq($xxs){
            font-size:27px;
            margin-bottom: 1.5rem;
        }
        .underline{
            height:2px;
            width:195px;
            background-color: $blue_color;
            margin-top:10px;
            @include mq($xls){
                width:146px;
            }
        }
    }
    .career-card{
        height:auto;
        padding:20px;
        background-color: $black_ligtened;
        min-height:100%;
        @include card;
        transition: all 0.5s ease-out;
        display: grid;
        align-content: space-between;
        &:hover{
            background-color: lighten($color: $black_ligtened, $amount: 6);
            img{
                 transform: rotate(360deg);
                 filter:none;
            }
            h2:first-child{
                color:$white_color;
                
            }
        }
        h2,h6{
            color:$white_color;
            font-family: $poppins_regular;
            float:left;
        }
        h2:first-child{
            color:$blue_color;
            transition: all 0.5s ease-out;
            
        }
        img{
            height:35px;
            width:auto;
            float:right;
            transition: all 0.5s ease-out;
            filter:hue-rotate(360deg) brightness(0) invert(1);
            &:hover{
                transform: rotate(360deg);
            }
        }
        .graphic{
            width:100%;
            display:grid;
            place-items: center;
            .illustartion {
                width: 80%;
                height: auto;
                align-self: center;
            }
        }
        h6{
            color:$gray_color;
            font-size:13px;
        }
        .small-img{
            height:16px;
        }
    }
    .common-question {
        
      h1{
          .underline {
              width:219px;
          }
      }
        padding-top:5rem;
         h5 {
             font-family: $poppins_regular;
             font-size: 20px;
             margin-bottom: 7px !important;
             color:$white_color;

         }
        .col-md-6{
            @include mq($xxlg) {
                -ms-flex: 0 0 (100%/ 3.1);
                flex: 0 0 (100%/ 3.1);
                max-width: (100%/ 3.1);
                margin-right: ((100%/3)-(100%/ 3.1));
                margin-top: ((100%/ 3)-(100%/ 3.1));
            }
            @include mq($xlg) {
                -ms-flex: 0 0 (100%/ 2.1);
                flex: 0 0 (100%/ 2.1);
                max-width: (100%/ 2.1);
                margin-right: ((100%/ 2)-(100%/ 2.1));
                margin-top: ((100%/ 2)-(100%/ 2.1));
            }
            @include mq($md) {
                -ms-flex: 0 0 (100%/ 1);
                flex: 0 0 (100%/ 1);
                max-width: (100%/ 1);
                margin-right: ((100%/ 1)-(100%/ 1));
            }
            
        }
        @include mq($xls) {
            font-size: 30px;
            margin-bottom: 1.5rem;
        }
        .question{
            transition: all 0.5s ease-out;
            &:hover {
                background-color: lighten($color: $black_ligtened, $amount: 6);
                transform: scale(1)!important;
                .inside {
                    width: 100%;
                    height: 100%;
                    background-color: $blue_color;
                }
            }
            @include card;
            padding:20px;
            padding-left: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
            @include mq($xls){
                border-bottom-left-radius: 18px;
                border-top-left-radius: 18px;
                padding-left: 20px;
            }
            display:flex;
            flex-direction: row;
            h2,
            h6 {
                color: $white_color;
                font-family: $poppins_regular;
                float: left;
            }
            .inside{
                height: 0%;
                transition: all 0.5s ease-out;
            }
            .line{
                width:10px;
                height:100%;
                background-color: gray;
                flex:auto;
                transition: all 0.5s ease-out;
                margin-right: 20px;
                @include mq($xls){
                    display: none;
                }
            }
            .all-content{
                padding-top: 15px;
                flex:auto;
                .top-elements{
                    h2{
                        margin-bottom: 15px!important;
                        color:$blue_color;
                    }
                    
                }
            }
            img {
                height: 35px;
                width: auto;
                float: right;
                transition: all 0.5s ease-out;

                &:hover {
                    transform: rotate(360deg);
                }
            }
            .content{
                clear:both;
                
               
                h6{
                   font-family: $poppins_regular;
                   font-size: 11px;
                   color:$gray_color;
                   margin-bottom:20px;
                   line-height: 1.5;
                }
            }
        }
    }
}