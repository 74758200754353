@import "../sass/variables.scss";
@import "../sass/mixins.scss";

#demo-reel,
#learn-more-id {
    position:fixed;
    top:0px;
    left:0px;
    z-index:30;
    width:100%;
    height:auto;
    background-color: $page_color;
    display:grid;
    place-items: center;
    // visibility:hidden;
    .cancel-btn,
    .cancel-btn-learn-more {
      position:fixed;
      top:0;
      left:0;
      z-index:35;
      width:100%;
      // padding-top:32px;

      #video-cancel,
      #learn-more-cancel {
        width:50px;
        height:auto;
        float:right;
        opacity:0.6;
        @include mq($xlg){
          margin-top:32px;
          margin-right:2%;
          width:30px;
        }
        @include mq($xxlg){
          margin-right: ($header-padding);
          margin-top:$header-padding/2;
        }
      }
    }
    #demo-vid,
    #learn-more-vid {
      min-width:100%!important;
      height:auto;
      // max-width:100vw;
    }
    .col-md-12 {
      --video--width: 640;
      --video--height: 360;

      position: relative;
      padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
      /* 41.66666667% */
      background: black;
      // @include card;
      border-radius: 0px!important;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height: 100vh;
      }

      border-radius:18px;
    }
}
