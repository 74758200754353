@font-face {
    font-family: boldpoppins;
    src: url("./Poppins-Bold.ttf");
}

@font-face {
    font-family: regularroboto;
    src: url("./Roboto-Regular.ttf");
}

@font-face {
    font-family: regularpoppins;
    src: url("./Poppins-Regular.ttf");
}

@font-face {
    font-family: thinpoppins;
    src: url("./Poppins-Thin.ttf");
}
@font-face {
    font-family: lightpoppins;
    src: url("./Poppins-Light.ttf");
}

@font-face {
    font-family: blackpoppins;
    src: url("./Poppins-Black.ttf");
}

@font-face {
    font-family: evolveregular;
    src: url("./MADE\ Evolve\ Sans\ Regular\ EVO\ \(PERSONAL\ USE\).otf");
}

@font-face {
    font-family: evolvebold;
    src: url("./MADE\ Evolve\ Sans\ Bold\ EVO\ \(PERSONAL\ USE\).otf");
}
