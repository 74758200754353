@import "../sass/variables.scss";
@import "../sass/mixins.scss";

#links {
  min-height: 100vh;
  background-color: $page_color;
  min-width: 100%;
  padding: 0px;
  // position: fixed;

  left: 0px;
  right: 0px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  @include mq($xls) {
    flex-direction: column;
  }

  .nav {
    min-width: 49%;
    max-width: 49%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: grid;
    place-items: center;
    pointer-events: none;
    @include mq($xls) {
      min-width: 100%;
      height:auto!important;
      min-height:0px;
      padding-top:75px;
      padding-bottom:45px;
    }

    .link-address {
      // margin-top:1.5vh;
      // margin-bottom:1.5vh;
      height: 8vh;
      pointer-events: auto;
      // min-width:100%;
      // margin:100%;
      .number {
        margin-right: 10px;
        @include poppins-regular-16;
        font-family: $poppins_light;
        text-rendering: optimizeLegibility;
        color: $gray_color;
        font-size: 16px;
        @include mq($xlg) {
          font-family: $poppins_regular;
          font-weight: 100;
        }
      }

      .actual-link {
        @include poppins-regular-16;
        color: $white_color;
        font-family: $poppins_light;
        font-size: 30px;
        user-select: none;
        text-rendering: optimizeLegibility;
        @include mq($xlg) {
          font-family: $poppins_regular;
          font-weight: 100;
        }

        .underline-link {
          // position:
          height: 2px;
          width: 50px;
          background-color: $blue_color;
          visibility: hidden;
        }
        @include mq($xxlg) {
          font-size: 50px;
        }
        @include mq($xlg) {
          font-size: 30px;
        }

        // &:hover {
        //
        //     color: adjust-hue($color: $blue_color, $degrees: 180)
        // }
      }

      .active {
        color: $blue_color;

        &:hover {
          color: $blue_color;
        }
      }
      .address-location {
        margin-bottom: 2vh;
        // display:flex;
        flex-direction: row;
      }
    }
  }

  .location {
    width: 25%;
    height: 100vh;
    display: grid;
    place-items: center;
    @include mq($md) {
      width: 100%;
      height:auto;
    }
    .address-location {
      margin-bottom: 2vh;
      // display:flex;
      flex-direction: row;
    }
    .social-icon-time {
      display: flex;
      flex-direction: row;
      .address-items {
        flex: auto;
        font-family: $poppins_light;
        text-rendering: optimizeLegibility;
        font-size: 12px;
        color: $white_color;
        line-height: 2;

        @include mq($xlg) {
          font-family: $poppins_regular;
        }
        // margin-right:3vh;
      }
    }

    .address-location-below {
      display: flex;
      flex-direction: row;
      .text-inside {
        font-family: $poppins_light;
        text-rendering: optimizeLegibility;
        font-size: 18px;
        color: $white_color;
        line-height: 2;
        @include mq($xlg) {
          font-family: $poppins_regular;
        }
      }
      .text-number {
        font-family: $poppins_light;
        text-rendering: optimizeLegibility;
        font-size: 9.6px;
        color: $gray_color;
        margin-right: 7px;
        @include mq($xlg) {
          font-family: $poppins_regular;
        }
      }
      .right-text {
        float: right;
        flex: auto;
      }
      .left-text {
        flex: auto;
        margin-right: 3vh;
      }
    }
    .title {
      font-family: $poppins_light;
      text-rendering: optimizeLegibility;
      color: $white_color;
      font-size: 18px;
      text-decoration: none;
      line-height: 2;
      @include mq($xlg) {
        font-family: $poppins_regular;
        font-weight: 100;
      }
    }
    .supporting {
      font-size: 12px;
      line-height: 2;
      text-decoration: none;
      color: $gray_color;
      font-family: $poppins_light;
      text-rendering: optimizeLegibility;
      @include mq($xlg) {
        font-family: $poppins_regular;
      }
    }
  }

  .line-art {
    flex: auto;
    max-width: 0.5px;
    min-width: 0.5px;
    background-color: $gray_color;
    opacity: 0.1;
    height: 100vh;
    @include mq($xls){
      display:none;
    }
  }
  .cancel-btn {
    float: left;
    z-index: 10;
    position: absolute;
    // top:32px;
    left: 0px;
    width: 100%;
    #cancel-img {
      width: 50px;
      height: auto;
      float: right;
      opacity: 0.6;
      @include mq($xxlg) {
        margin-right: ($header-padding/2);
        margin-top: $header-padding/2;
      }

      @include mq($xlg) {
        margin-top: 32px;
        // margin-right:2%;
        margin-right: ($header-padding/2);
      }
    }
  }
}
#service-link {
  display: none;
  width: 0px;
  overflow: hidden;
  height: 0;
}
