@import "../sass/variables.scss";
@import "../sass/mixins.scss";

// 1080*1350


.fixed-container{
    display: none;
}

.privacy {
    position: fixed;
    z-index: 1000;
    top:0;
    left:0;
    min-width: 100vw;
    min-height:100vh;
    height:100vh;
    overflow-y:scroll;
    padding: 5vw 10vw 5vw 10vw;
    background-color: rgba($color: #000000, $alpha: 1);
    display:none;
    outline:0;
    border:0px solid #000;
    
    .wrapper{
        background-color: $black_ligtened;
        border-radius: 30px;
        margin-top:5vw;
        .header-img{
          img{
              width:100%;
              height:auto !important;
              border-radius: 30px;
          }  
        }
        .project-body{
            padding: 5vh 5vw 3vh 5vw;
            h1{
                font-family: $bold_poppins;
                color:$white-color;
                font-size:3rem;
            }
            h3{
                font-family: $poppins_thin;
                color:$white-color;
                font-size:3rem;
                padding-bottom: 4vh;
            }
            h6{
                font-family: $poppins_regular;
                color:$white-color;
                font-size:14px;
                line-height: 24px;
                padding-bottom: 5vh;
                opacity: 0.75;
            }
            .row{
                .col-md-6{
                    padding-bottom: 40px;
                    img{
                        width:100%;
                        height:auto;
                    }
                    &:nth-child(1) {
                        padding-right:20px
                    }
                    &:nth-child(2) {
                        padding-left:20px
                    }
                    &:nth-child(3) {
                        padding-right:20px
                    }
                    &:nth-child(4) {
                        padding-left:20px
                    }
                    @include mq($xls){
                       &:nth-child(1) {
                           padding-right:0px
                       }

                       &:nth-child(2) {
                           padding-left:0px
                       }

                       &:nth-child(3) {
                           padding-right:0px
                       }

                       &:nth-child(4) {
                           padding-left:0px
                       }
                    }
                }
                .col-md-12{
                    --video--width: 640;
                    --video--height: 360;

                    position: relative;
                    padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
                    /* 41.66666667% */
                    overflow: hidden;
                    max-width: 100%;
                    background: black;
                    iframe{
                       position: absolute;
                       top: 0;
                       left: 0;
                       width: 100%;
                       height: 100%;
                    }
                }
                .left-arrow{
                    font-size:30px;
                    color:$white-color;
                    margin-top:100px;
                    margin-bottom:100px;
                }
            }
        }
    }
}
.privacy{
    display:block;
    position: relative;
    z-index: 0;
    top:0;
    left:0;
}