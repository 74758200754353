@import "../sass/variables.scss";
@import "../sass/mixins.scss";

.title-text {
    font-family: $bold_poppins;
    font-size: 60px;
    color: $white_color;
    margin-bottom: 45px;
    margin-top: 45px;

    @include mq($xlg) {
        font-size: 40px;
    }
}



#project {
    width: calc((100%/6) - 1rem);
    background-color: rgba($color: #000000, $alpha: 0);

    @include mq($xlg) {
        width: calc((100%/3) - 1rem);

        &:nth-child(1) {
            margin-bottom: 2rem;
        }
    }

    @include mq($xls) {
        width: 100%;
        margin-bottom: 2rem;

        &:nth-child(6) {
            margin-bottom: 0px;
        }
    }

    .img-3 {
        overflow: hidden !important;
        border-radius: 18px !important;
        @include card;
        @include project-image("../images/projects/1.jpg");

        .back-img {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: grid;
            align-content: flex-end;
            padding: 15px;
            color: $white_color;
            font-family: $bold_poppins;
            font-size: 20px;
            transition: all 0.3s ease-out;
            &:hover {
                transform: scale(1.1);
            }
        }
        .project-title{
            padding-left: 15px;
            color: $white_color;
            font-family: $bold_poppins;
            font-size: 20px;
            z-index:10;
            position: absolute;
            transform: translateY(-45px);
        }
    }
}

.title {
    width: 100%;
    display: grid;
    justify-content: flex-start;


}



.title-text {
    font-family: $bold_poppins;
    font-size: 60px;
    color: $white_color;
    margin-bottom: 2rem;
    margin-top: 2rem;

    @include mq($xlg) {
        font-size: 40px;
    }

    @include mq($xls) {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}