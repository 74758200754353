@import "../sass/variables.scss";
@import "../sass/mixins.scss";
#clients{
    padding-left: $header-padding;
    padding-right: $header-padding;
    @include mq($md){
        padding-left: 15px;
        padding-right: 15px;
    }
    .single-tool{
        &:first-child{
            img{
                height:80px;
                width:auto;
                @include mq($xls){
                    height: 70px;
                }
            }
        }
        &:last-child{
            width:100%!important;
        }
    }
    
}
.all-journals {
    @include mq(md) {
        padding-left: 15px;
        padding-right: 15px;
    }
}