@import "../sass/variables.scss";
@import "../sass/mixins.scss";
.logo-img {
    flex: auto;
    pointer-events: auto !important;
    position: absolute;
    top: 5vw;
    z-index: 100;
    left: (10vw/2);

    @include mq($xxlg) {
        top: 5vw;
    }

    @include mq($xlg) {
        top: 32px;
    }

    @include mq($md) {
        left: 15px;
    }
    @include mq($xls){
        left:15px;
    }

    img {
        height: 40px;
        width: auto;
        pointer-events: auto !important;
        top: 0px !important;

        @include mq($xlg) {
            top: 0px !important;
        }
        @include mq($xls){
            left:0px;
        }
        @include mq($md){
            left:0px;
        }
    }
}