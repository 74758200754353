$page_color:#09090D;
$blue_color:#ffffff;
$bold_poppins:boldpoppins;
$white_color:rgb(233, 233, 233);
$black_ligtened:lighten(#09090D,6);
$blue:#3399cc;
$roboto_regular:regularroboto;
$poppins_regular:regularpoppins;
$poppins_thin:thinpoppins;
$poppins_light:lightpoppins;
$poppins_black:blackpoppins;
$evolve_regular:evolveregular;
$gray_color:#fff;
$small-device:500px;
$xxs:320px;
$xls:600px;
$xlsh:500px;
$xms:375px;
$md:780px;
$lg:1024px;
$xlg:1440px;
$xxlg:5000px;
$headervh:100vh;
$header-padding:10vw;
$divider:(4/3);

