@import "../sass/variables.scss";
@import "../sass/mixins.scss";

// @import " animation";

#main-home {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
//   min-height:calc(100vh - 32px)!important;
  height:calc(100vh - 0px)!important;
  // background-color:$page_color;
  padding-top: 32px;
  padding-left: 2%;
  padding-right: 2%;
  min-width: 100%;
  // max-height:700px;
  background-color: rgba(144, 159, 159, 0);

  user-select: none;
  pointer-events: none !important;

    @include mq($xxlg) {
      padding-left: $header-padding;
      padding-right: $header-padding;
      padding-top: $header-padding/2;
      display: grid !important;
      align-content: center !important;
    }

    @include mq($xlg) {
      padding-top: 32px;
    }

    @include mq($md) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @include mq($xls) {
      padding-left: 15px;
      padding-right: 15px;
      padding-top:0px;
    }

    @include header-layout;

  .top-wrapper {
    display: flex;
    flex-direction: row;
    pointer-events: none !important;
    position: fixed;
    top: (10vw/2);
    left: 10vw;
    z-index: 100;
  }

  .other-social-media {
    @include mq($xxlg) {
      visibility: visible;
    }

    @include mq($xlg) {
      visibility: visible;
    }

    .socio-icon {
      pointer-events: auto !important;
      height: 24px;
      width: auto;
      color: $white_color;
      float: left;
      margin-right: 85px;
      transition: color 0.3s ease-out;
      margin-top: 30px;

      &:hover {
        color: $blue_color;
      }

      @include mq($xls) {
        height: 16px;
        margin-right: 20px;
      }
    }

    .demo-reel {
      color: $white_color;
      font-size: 30px;
      font-family: $bold_poppins;
      line-height: 0.8;
      margin-right: 30px;
      -webkit-touch-callout: none;
      -webkit-user-select: none !important;
      -khtml-user-select: none !important;
      -moz-user-select: none !important;
      -ms-user-select: none !important;
      user-select: none !important;

      @include mq($xls) {
        font-size: 23px;
      }
    }
  }

  .middle-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    pointer-events: none !important;
    min-height: 100vh;

    @include mq($xls) {
      min-height: 0vh;
    }

    .header-text {
      flex: auto;
      min-width: 50%;
      min-height: 75%;
      max-height: 100%;
      // background-color:red;
      text-align: left;
      display: grid;
      align-items: center;
      pointer-events: none !important;

      @include mq($xxlg) {
        min-height: 100%;
        max-height: 100%;
      }

      @include mq($xlg) {
        min-height: 100%;
        max-height: 75%;
      }

      @include mq($xls) {
        font-size: 40px;
        max-width: 100%;
      }

      @include mq($md) {
        font-size: 26px;
        max-width: 100%;
      }

      .text {
        max-width: 45%;
        font-family: $bold_poppins;
        font-size: 50px;
        line-height: 1.2;
        font-weight: 400px;
        text-transform: none;
        color: $white_color;
        margin-left: 10%;
        margin-bottom: 1%;
        pointer-events: none !important;

        @include mq($xxlg) {
          margin-left: 0px;
          font-size: 5rem;
          max-width: 100%;
        }

        @include mq($xlg) {
          font-size: 50px;
          max-width: 50%;
          font-size: 40px;
        }

        @include mq($md) {
          font-size: 30px;
          max-width: 100%;
        }

        @include mq($xls) {
          font-size: 35px;
          max-width: 100%;
        }

        .stroke {
          font-family: $poppins_thin;
          // -webkit-text-stroke:1px white !important;
          // -webkit-text-fill-color:#ffffff00 !important;
        }
      }

      .company-name {
        font-family: $poppins_thin;
        color: $white_color;
        max-width: 100%;
        margin-left: 10%;
        pointer-events: none !important;
        line-height: 1;
        text-transform: none;
        @extend .text;

        @include mq($xxlg) {
          margin-left: 0px;
        }

        @include mq($md) {
          max-width: 100%;
          font-size: 16px;
        }

        @include mq($xls) {
          max-width: 100%;
          font-size: 35px;
        }

        .bold {
          font-family: $bold_poppins;
        }

        //   text-transform: capitalize;
      }

      .description {
        max-width: 30%;
        margin-left: 10%;
        pointer-events: none !important;
        @include poppins-regular-16;
        margin-bottom: 2%;

        @include mq($xxlg) {
          margin-left: 0px;
        }

        @include mq($xls) {
          max-width: 75%;
        }

        @include mq($xxs) {
          max-width: 100%;
        }
      }

      .contact-us-btn {
        height: 60px;
        max-width: 15%;
        border-style: solid;
        border-width: 2px;
        border-color: $white_color;
        border-radius: 36px;
        margin-left: 10%;
        text-align: center;

        @include mq($xxlg) {
          margin-left: 0px;
        }

        @include mq($md) {
          height: 50px;
          margin-top: 20px;
          min-width: 20vw;
        }

        pointer-events: auto !important;
        @include poppins-regular-16;
        color: $white_color;
        display: grid;
        place-items: center;

        &:hover {
          background-color: rgba(253, 254, 255, 0.493);
        }

        @include mq($xls) {
          max-width: 50%;
        }
      }
    }

    .social-media {
      min-height: 100%;

      display: grid;
      place-items: center;
      pointer-events: auto !important;

      @include mq($xxlg) {
        visibility: hidden;
      }

      @include mq($xlg) {
        visibility: visible;
      }

      @include mq($xxs) {
        // visibility: hidden;
      }

      .socio-icon {
        font-size: 30px;
        height: auto;
        color: $white_color;
        line-height: 75px;
        font-weight: lighter;
        pointer-events: auto !important;

        &:hover {
          color: $blue_color;
        }
      }

      .line {
        height: 100px;
        width: 0px;
        background-color: $blue_color;
        margin-left: 50%;
        pointer-events: auto !important;
      }
    }
  }
}

#other-content {
  @include mq($xxlg) {
    position: relative;
    // top: $headervh+7;
    // left:0px;
    z-index: 2;
  }

  @include mq($xlg) {
    position: relative;
    // top: 105vh;
    // left:0px;
    z-index: 2;
  }
}

#menu-item {
  right: $header-padding;
  z-index: 100;
  top: 32px;

  @include mq($xxlg) {
    top: 5vw;
  }

  @include mq($xlg) {
    top: 32px;
  }

  @include mq($md) {
    right: 15px;
  }

  .line1,
  .line2,
  .line3 {
    width: 40px;
    height: 2px;
    background-color: $white_color;
    margin-bottom: 8px;
    float: right;
    transition: all 0.3s ease-out;
    z-index: 100;
  }
}

.menu-icon {
  max-width: 40px;
  float: right;
  height: 100px;
  // background-color:red;
  flex: auto;
  pointer-events: auto !important;
  position: absolute;
}

.animate-hamburger {
  .line2 {
    transform: translateX(15vw);
    background-color: red;

    @include mq($xls) {
      transform: translateX(15vh);
    }
  }

  .line3 {
    position: relative;
    top: -11px;
    transform: rotate(45deg);
  }

  .line1 {
    position: relative;
    top: 11px;
    transform: rotate(-45deg);
  }
}

#contact-us-btn-id {
  transition: all 0.3s ease-out;

  &:hover {
    color: $blue;
  }
}
