@import "../sass/variables.scss";
@import "../sass/mixins.scss";

#brands{
    .img-bg{
        position:absolute;
        top:0;
        left:0;
        z-index:-1;
        width:100vw;
        min-height:100vh;
        background-image: url("https://res.cloudinary.com/dbkjsfg3t/image/upload/v1615887319/images/brands/pattern_pm1nzd.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-blend-mode: difference;
        filter:grayscale(93%) ;
    }
    .logo{
        position:absolute;
        left: $header-padding;
        z-index: 3;
        top:32px;
        height: 61px;
        width: auto;
        @include mq($xxlg) {
            top: $header-padding/2;
        }

        @include mq($xlg) {
            top: 32px;
        }
    }
    .header{
        color:$white_color;
        font-family:$poppins_thin;
        padding-left: ($header-padding);
        margin-top: $header-padding;
         @include mq($lg) {
             margin-top: 16%;
         }
         @include mq($md) {
             margin-top: 23%;
         }
        @include mq($xls){
          margin-top: 40%;
        }
        h1{
            text-transform: uppercase;
            @include mq($xls){
                font-size:35px;
            }
            @include mq($xms){
                font-size:35px;
            }
            @include mq($xls){
                font-size:26px;
            }
        }
        h3{
            font-size: small;
            max-width:15vw;
            margin-top: 30px;
            line-height:1.7;
            @include mq($xlg){
                 max-width:27vw;
            }
            @include mq($lg){
                 max-width:37vw;
            }
            @include mq($md){
                 max-width:51vw;
            }
            @include mq($xls){
              max-width:90vw;
            }
        }
        .our-brands{
            margin-top:100px;
        }
        .logos{
            margin-top: 80px;
            .center{
                width:100%;
                display:grid;
                place-items: center;
            }
            .col-md-4{
                margin-bottom: 30vh;
                @include mq($xls){
                    margin-top:30px;
                }
                h3{
                    @include mq($lg){
                        max-width: 26vw;
                    }
                    @include mq($xls){
                        max-width: 90vw;
                    }
                }
                img{
                    height:200px;
                    @include mq($xlg){
                        height:130px;
                    }
                    @include mq($md){
                        height:93px;
                    }
                    @include mq($xls){
                      height:130px;
                    }
                    width:auto;
                    filter:grayscale(100%); 
                    transition: all 0.5s ease-out;
                    &:hover{
                        filter:grayscale(0%);
                    }
                }
            }

        }
    }
    .blue-color{
        color:$blue_color;
    }
}