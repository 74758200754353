@import "../sass/variables.scss";
@import "../sass/mixins.scss";

#team-card {
    width:32%;
    background-color: rgba($color: #000000, $alpha: 0);
    @include mq($lg){
        width:48%;
    }
    @include mq($xls){
        width:100%;
    }
    .cards {

        min-height: 100vh;
        background-color: $black_ligtened;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: grid;
        align-content: flex-end;
        // margin-right: 2rem;
        margin-bottom: 2rem;
        border-radius: 18px;
        padding: 30px;
        padding-bottom: 15px;
        background-color: rgba($color: #000000, $alpha: .2);
        background-blend-mode: darken;
        transition: all 0.3s ease-out;
        &:hover{
            background-color: rgba($color: #000000, $alpha: .0);
        }

        .number {
            color: $blue_color;
            font-size: 3.5rem;
            font-family: $poppins_light;
            line-height:1;
        }
        .name{
            color:$blue_color;
            font-family: $poppins_light;
            font-size:16px;

        } 
        .postion{
            padding-left: 1.25rem;
        }
        .title {
            color:$blue_color;
            font-family: $bold_poppins;
            font-size:16px;

        }
    }
}