@import "../sass/variables.scss";
@import "../sass/mixins.scss";
#service-details {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $page_color;
  padding-right: $header-padding;
  padding-left: $header-padding;
  padding-top: 42px;
  @include mq($xxlg) {
    padding-top: 5vw;
  }

  @include mq($xlg) {
    padding-top: 52px;
  }
  @include mq($md){
    padding-left: 15px;
    padding-right: 15px;
  }
  .close-image-wrapper {
    margin-bottom: (47px / $divider);
  }
  .career-details-card {
    // min-height: 90vh;
    min-height: 80vh;
    border-radius: (18px / $divider);
    background: #13131c;
    padding: (20px / $divider);
  }
  .career-image {
    height: 80vh;
    border-radius: (18px / $divider);
    background-size: cover;
    background-position: center;
  }
  .details {
    padding-left: 1rem;
    @include mq($xls){
      padding-left: 0px;
    }
    h3 {
      font-family: $bold_poppins;
      font-weight: bold;
      font-size: (30px / $divider);
      line-height: 1;
      text-align: left;
      color: $white_color;
      margin-bottom: (20px / $divider);
    }
    h6 {
      font-family: $poppins_thin;
      font-weight: 300;
      font-size: (16px / $divider);
      line-height: (24px / $divider);
      text-align: left;
      color: $white_color;
      margin-bottom: 20px;
    }
    img {
      height: (29.99px / $divider);
      background: transparent;
      margin-right: (20px / $divider);
      //   margin-top:(-20px/$divider);
    }
    .career-title{
      @include title_text;
      padding-top: 0px;
      @include mq($xls){
        padding-top: 15px;
      }
    }
    .career-details{
      @include poppins-regular-16;
      font-size:13px;
      opacity:.5;
    }
    .career-requirement{
      @include title_text;
      margin-bottom: 20px;
    }
    .reuquirement{
      margin-bottom: 15px;
      .rounded-circle{
        max-height:30px;
        max-width:30px;
        min-width:30px;
        min-height:30px;
        background-color: #000;
        font-size:13px;
        display:grid;
        place-items: center;
        font-family: $bold_poppins;
        color:$white_color;
        margin-right: 1rem;
      }
      .the-reuquirement{
        @include poppins-regular-16;
        font-size:13px;
        opacity:.5;
      }
    }
  }
}
