@import "../sass/variables.scss";
@import "../sass/mixins.scss";


#faq {
    @include mq($md) {
        padding-left: 15px;
    }

    .faq-data {
        margin-bottom: 20px;
    }

    .single-faq {
        width: 100%;
        @include card;
        @include title_text;
        padding: 10px;

        @include mq($md) {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }
         @include mq($xls) {
            border-top-left-radius: 18px;
            border-bottom-left-radius: 18px;
            padding: 20px;
        }

        .numbering {
            position: absolute;
            height: 30px;
            width: 30px;
            background-color: $black_ligtened;
            display: grid;
            place-items: center;
            box-shadow: 0px 0px 0px 15px #000;
            transform: translateY(-5px) translateX(-(45px/2));
            z-index: 3;

            @include mq($md) {
                transform: translateY(-5px) translateX(-(0px/2));
            }

            @include mq($xls) {
                display: none;
            }
        }

        h6 {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 1;

            .title {
                margin-left: 45px;

                @include mq($lg) {
                    max-width: 90%;
                    margin-left: 60px;
                    font-size: 16px;
                    line-height: 1.3;
                }
                @include mq($md) {
                    max-width: 90%;
                    margin-left: 60px;
                    font-size: 16px;
                    line-height: 1.3;
                }

                @include mq($xls) {
                    max-width: 86%;
                    margin-left: 0px;
                    font-size: 16px;
                    line-height: 1.3;
                }

                .button {
                    position: absolute;
                    right: calc(10vw + 25px);
                    transform: translateY(-5px);

                    @include mq($md) {
                        right: 35px;
                    }

                    img {
                        width: 30px;

                        @include mq($xls) {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

    .details {
        background-color: $white_color;
        padding: 20px;
        padding-top: 40px;
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 18px;
        transform: translateY(-20px);
        z-index: -1;
    }
}