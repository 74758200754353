@import "../sass/variables.scss";
@import "../sass/mixins.scss";

.snap-footer {
    scroll-snap-align: start !important;
}

#footer-content {
    #message_error{
        color:red!important;
    }
    min-height: 100vh;
    padding-top: 10vh;
    padding-left: $header-padding;
    padding-right: $header-padding;
    font-family: $poppins_light;
    color: white;
    font-size: 13px;
    font-weight:900;

    .email-message {
        color: $blue;
        text-align: center;
    }

    @include mq($xls) {
        line-height: 1.8 !important;
        padding-left: 0px;
        padding-right: 0px;
    }

    .privacy-line {
        @include mq($xls) {
            margin-top: 7px;
        }
    }

    .links {
        a {
            transition: all 0.3s ease-out;

            &:hover {
                color: $blue
            }
        }
    }

    @include mq($xxlg) {
        padding-top: 20vh;
    }

    @include mq($xlg) {
        padding-top: 13vh;
    }

    @include mq($xls) {
        padding-bottom: 2vh;
    }

    img {
        height: 70px;
        width: auto;
    }

    .info {
        margin-bottom: (10vh/3);
    }

    .subsribe {
        margin-bottom: 10vh;

        @include mq($xxlg) {
            margin-bottom: 18vh;
        }

        @include mq($xlg) {
            margin-bottom: 10vh;
        }
    }

    .subscribe-text {
        margin-bottom: 2vh;
    }

    .form-control {
        border-top-right-radius: 0px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 0px;

        @include mq($md) {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            height: 45px;
            margin-bottom: 15px;
        }

        &:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba($color: lighten($color: $page_color, $amount: 40), $alpha: 0.25)
        }
    }

    .form-control::placeholder {
        color: lighten($color: $page_color, $amount: 6);
        opacity: 1;
        font-family: $poppins_light;
    }

    .btn {
        border-top-right-radius: 20px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 20px;
        background-color: lighten($color: $page_color, $amount: 10);
        border-color: lighten($color: $page_color, $amount: 10);
        font-family: $poppins_light;

        @include mq($md) {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            height: 45px;
        }

        &:hover {
            background-color: lighten($color: $page_color, $amount: 20);
            border-color: lighten($color: $page_color, $amount: 20);
        }

        &:focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba($color: lighten($color: $page_color, $amount: 40), $alpha: 0.25)
        }
    }

    .icon {
        height: 20px;
        width: auto;
        transition: all 0.3s ease-out;

        &:hover {
            color: $blue;
        }
    }

    @include mq($xls) {
        .col-md-4 {
            margin-bottom: 30px;
            display: grid;
            place-items: center;
        }

        .icon {
            margin-right: 30px;
        }

        .no-margin {
            margin-right: 0px !important;
        }
    }
    .information{
        @include mq($xls){
            margin-bottom: 3.33333vh;
        }
    }
    span{
        @include mq($xls){
            display: block;
        }
    }
}