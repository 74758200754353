@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "./components/sass/variables.scss";
@import "./components/sass/mixins.scss";

.card{
    border-width: 0px!important;
}
.custom-margin {
    margin-top: 60px;
}
.hide-menu{
  display:none!important;
}
.show-menu{
  display:block!important;
}

html {
    // scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
}

html::-webkit-scrollbar {
    display: none;
}

// html{
//     scrollbar-width: none;
// }
::selection {
    color: $white_color;
    background-color: $blue;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}
img{
    &::before{
        background-color: red!important;
        height:100%;
        width:100%;
    }
}

.guez-group {
    height: 30px !important;
}

.container {
    @include mq($xlg) {
        max-width: 1440px;
    }

    @include mq($xxlg) {
        max-width: 2560px;
    }
}

.special-container {
    padding-left: $header-padding;
    padding-right: $header-padding;

    @include mq($md) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.service-container {
    @include mq($xxlg) {
        padding-left: 0;
        padding-right: 0;
    }

    @include mq($lg) {
        padding-left: 10vw;
        padding-right: calc(10vw - 84px);
    }

    @include mq($md) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

body {
    background-color: $page_color;
    height: 100vh;
    width:100vw;
    overflow-x: hidden;

    @include mq($xxs) {
        margin: 0px;
        padding: 0px;

    }

    // @include mq(600px) {
    //     padding-left: 20px;
    //     padding-right: 20px;
    // }

}

#home {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 1;
    padding: 0px;
    margin: 0px;
    pointer-events: auto;
    // opacity:0.5;
    // background-color: red;
    min-height: 100vh;
    @include header-layout;

    // background-color:red;
    // background-color: lighten($color: $page_color, $amount: 5);
    iframe {
        width: 100%;
        height: 100vh;
        min-width: 100%;
        min-height: 100%;
        border-width: 0px;
        @include header-layout;
    }
}

#projects {

    padding-left: $header-padding;
    padding-right: $header-padding;

    @include mq(600px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    @include mq($md) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .center-content {
        width: 100%;
        display: grid;
        place-items: center;
    }

    width:100%;
}


#cycle-container {
    // margin-bottom: 88px;

    display: flex;

    @include mq($xls) {
        .row {
            margin: 0px;
        }
    }

    @include mq($xxs) {
        .row {
            margin: 0px;
        }
    }

}


#cycle {
    .card {
        padding-bottom: 20px;
    }

    // margin-bottom: 4.8%;

    @include mq($xxlg) {
        .card {
            min-width: 15.66666666666667%;
            margin-bottom: 0%;
            padding-left: 2.342606149341142%;
            padding-right: 2.342606149341142%;
            margin-left: 1%;
            margin-right: 0;

            &:nth-child(1) {
                margin-left: 0px;
            }

        }
    }

    @include mq($xlg) {
        .card {
            min-width: 32%;
            margin-bottom: 2%;
            padding-left: 2.342606149341142%;
            padding-right: 2.342606149341142%;
            margin-left: 2%;
            margin-right: 0;

            &:nth-child(1) {
                margin-left: 0px;
            }

            &:nth-child(4) {
                margin-left: 0px;
            }
        }
    }

    @include mq($lg) {
        .card {
            min-width: 48%;
            margin-bottom: 4%;
            padding-left: 2.342606149341142%;
            padding-right: 2.342606149341142%;
            margin-left: 4%;
            margin-right: 0;

            &:nth-child(1),
            &:nth-child(3),
            &:nth-child(5) {
                margin-left: 0px;
            }

            &:nth-child(4) {
                margin-left: 4%;
            }

            &:nth-child(6) {
                margin-bottom: 0%;
            }

            &:nth-child(5) {
                margin-bottom: 0%;
            }
        }
    }

    @include mq($xls) {
        .card {
            min-width: 48%;
            margin-bottom: 4%;
            padding-left: 2.342606149341142%;
            padding-right: 2.342606149341142%;
            margin-left: 4%;
            margin-right: 0;

            &:nth-child(1),
            &:nth-child(3),
            &:nth-child(5) {
                margin-left: 0px;
            }

            &:nth-child(4) {
                margin-left: 0%;
            }

            &:nth-child(5) {
                margin-bottom: 4%;
            }

            &:nth-child(6) {
                margin-bottom: 0%;
            }
        }
    }


    @include mq($xls) {
        .card {
            min-width: 100%;
            margin: 0;
            padding: 0;
            padding-bottom: 20px;
            margin-bottom: 7.8125%;
        }



    }

    @include mq($xxs) {
        .card {
            min-width: 100%;
            margin: 0;
            padding-bottom: 20px;
            margin-bottom: 7.8125%;
        }



    }

    .cycle-item {
        @include card;
        padding-left: 20px;
        padding-right: 20px;

        @include mq($xls) {
            padding-left: 10px;
            padding-right: 10px;
        }

        height: auto;
        // @include mq($md){
        //     -ms-flex: 0 0 30.333333%;
        //     flex: 0 0 30.333333%;
        //     max-width: 50.333333%

        // }

        .title {
            @include in_btn_space;
            color: $white_color;
            font-family: $bold_poppins;
            font-size: 25px;
            text-align: center;
            width: 100%;

            @include mq(600px) {
                font-size: 19px;
            }
        }

        .icon {
            width: 100%;
            display: grid;
            place-items: center;

            img {
                max-height: 147px;
                width: auto;
                margin-top: 40px;
            }
        }

        .cycle-details {
            padding-right: 12px;
            padding-left: 12px;
            @include in_btn_space;
            font-family: $poppins_regular;
            font-size: 13px;
            color: $gray_color;
            width: 100%;
            margin-bottom: 10px;
            margin-top: 60px !important;
        }

        .learn-more {
            width: 100%;
            display: grid;
            place-items: center;

            .btn-learn-more {
                @include hover;
                border-radius: 16px;
                border-style: solid;
                border-width: 10px;
                width: 50%;
                padding-top: 12px;
                padding-bottom: 12px;
                border-color: $page_color;
                background-color: $blue_color;
                font-family: $bold_poppins;
                font-size: 14px;
                color: $white_color;
                text-transform: capitalize;
                position: relative;
                top: 30px;

                @include mq(600px) {
                    top: 0px;
                    border-width: 0px;
                    border-radius: 4px;
                    margin-bottom: 20px;
                }

                @include mq($md) {
                    top: 0px;
                    border-width: 0px;
                    border-radius: 4px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.learn-more-container {
    width: 100%;
    display: grid;
    place-items: center;
    margin-bottom: 44px;

    .btn {
        padding: 16px;
        border-style: solid;
        border-color: $blue_color;
        border-radius: 2px;
        text-align: center;

    }
}

.tools-box {
    @include container;
}

.tool {
    img {
        height:auto;
        width: 100%;
        margin-right: 30px;
        filter: grayscale(100%);
        transition: all 0.5s ease-out;

        &:hover {
            filter: grayscale(0%);
        }

        @include mq($md) {
            margin-right: 0px;
        }

        @include mq(600px) {
            margin-bottom: 30px;
        }
    }

    #mongo-db {
        width: 324.05px;
        height: 100px;

        @include mq($xls) {
            width: 100%;
            height: auto;
        }

        @include mq($xxs) {
            width: 100%;
            height: auto;
        }
    }

    #express {

        @include mq($xls) {
            width: 100%;
            height: auto;
        }

        @include mq($xxs) {
            width: 100%;
            height: auto;
        }
    }

    @include mq($md) {
        display: grid;
        justify-content: flex-end;
    }

}

.tool-row {
    margin-bottom: 30px;

    @include mq(600px) {
        .col-sm {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }

    }

}

#tools {
    margin-bottom: 88px;

    @include mq($xls) {

        .col-sm-2,
        .tool {
            width: 50%;
        }

        .row {
            margin: 0px;
        }

    }

    @include mq($xxs) {

        .col-sm-2,
        .tool {
            width: 50%;
        }

        .row {
            margin: 0px;
        }

    }
}

#buttom-icons {
    @include mq($md) {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;

        .tool {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;

        }
    }

    @include mq($lg) {
        .tool {
            margin-right: 3.90625%;
        }
    }
}

#clients {
    margin-bottom: 88px;
    @include container;
}

.question-card {
    @include card;

    &:hover {
        background-color: lighten($color: $black_ligtened, $amount: 6);
        transform: scale(1) !important;
    }

    height: auto;
    width: 100%;
    align-self: center;
    margin-right: 42px;
    margin-bottom: 50px;

    .question-icon-holder {
        width: 100%;
        display: grid;
        place-items: center;


        .question-icon {
            background-color: $blue_color;
            color: $white_color;
            font-family: $poppins_regular;
            font-size: 24px;
            width: 60px;
            height: 60px;
            display: grid;
            place-items: center;
            border-style: solid;
            border-width: 10px;
            position: relative;
            top: -30px;
            border-color: $page_color;
            @include hover;
        }

    }

    .question-title {
        width: 100%;
        text-align: center;
        font-family: $bold_poppins;
        font-size: 19.5px;
        color: $white_color;
        margin-bottom: 20px;
        position: relative;
        top: -25px;

    }

    .question-detail {
        font-family: $poppins_regular;
        font-size: 13px;
        position: relative;
        top: -30px;
        color: $gray_color;
        padding-left: 42px;
        padding-right: 42px;
    }
}

#faq {
    padding-bottom: 60px;
    @include container;


    @include mq($md) {
        padding-right: 15px;
        padding-left: 0px;

        .col-md {
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%
        }

        .question-card {
            margin-right: 0px;

        }
    }

    @include mq(600px) {
        .col-md {
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%
        }
    }

}

.form-group {
    input {
        height: 60px;
    }

    input::placeholder {
        color: $gray_color;
        font-family: $poppins_regular;
        font-size: 16px;
    }

    textarea {
        color: $gray_color;
        height: 240px;
        font-family: $poppins_regular;
        font-size: 16px;
    }

    button {
        width: 100%;
        color: $white_color;
        font-family: $poppins_regular;
        font-size: 16px;
        background-color: $blue_color;
        height: 60px;
    }
}

.icon-40 {
    img {
        height: 40px;
        width: auto;
        float: right;
        margin-right: 20px;
    }
}

.address {
    margin-left: 42px;

    @include mq($xls) {
        margin-left: 0px;
    }

    @include mq($xxs) {
        margin-left: 0px;
    }
}

.address-text {
    font-family: $poppins_regular;
    font-size: 16px;
    color: $gray_color;
}

.address-detail {
    float: left;
    height: 40px;
    display: grid;
    place-items: center;
}

.address-info {
    @include in_btn_space;
}


#contact-us {
    margin-bottom: 88px;
    @include container;

    @include mq($md) {
        #form {

            -ms-flex: 0 0 81.666667%;
            flex: 60 0 81.666667%;
            max-width: 81.666667%;

            @include mq($xls) {
                -ms-flex: 0 0 100%;
                flex: 60 0 100%;
                max-width: 100%;
            }

            @include mq($xxs) {
                -ms-flex: 0 0 100%;
                flex: 60 0 100%;
                max-width: 100%;
            }

        }

        #details {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            min-width: 100%;

            .responsive-icon {
                position: relative;

                // left: -25px;
                @include mq($xls) {
                    left: 0;
                }

                @include mq($xxs) {
                    left: 0;
                }
            }

            .address-info {
                flex: auto;
                margin-right: 0px;

            }
        }
    }

    .form-elements {
        @include mq($md) {
            width: 100%;
            display: grid;
            place-items: center;
        }
    }
}

.row {
    @include mq($xls) {
        margin: 0px;
    }

    @include mq($xxs) {
        margin: 0px;
    }
}

#background {
    position: absolute;
    left: 0px;
    right: 0px;
    z-index: -2;
    max-width: 100vw !important;
    width: 100vw;
    overflow: hidden;
    // opacity:0.8;
    // background-color: red;
    //    background-color: green;
    background-color: lighten($color: $page_color, $amount: 5);
    // filter:contrast(160%);
    // filter:grayscale(100%);
    // background-color: #000000;
}

#stars {
    position: absolute;
    left: 0px;
    right: 0px;
    z-index: -3;
    opacity: 0.5;
    // background-color: red;
    background-color: lighten($color: $page_color, $amount: 5);
    // opacity: 0.7;
    // background-color: blue;
}

#overlay {
    position: absolute;
    left: 0px;
    right: 0px;
    z-index: -1;
    opacity: 0.5;
    // background-color: red;
    background-color: lighten($color: $page_color, $amount: 5);
}

.no-gutters-cst {
    margin: 0px !important;
    padding: 0px !important;
}

.small-margin {
    margin-bottom: 15px;
}